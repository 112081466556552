import { Environment } from 'src/app/shared/types/environment.type';

export const environment: Environment = {
  code: 'rcsc',
  loginBackgroundImage: 'login-background.png',
  loginLogoImage: 'login-logo.png',
  appIcon: 'app-icon.png',
  hostUrl: 'https://rcsc-api.manydigital.com/',
  production: true,
  clubName: 'Royal Charleroi S.C.',
  primaryColor: '#1B2025',
  secondaryColor: '#A89267',
  tertiaryColor: '#FFDE14',
  pushDefaultLanguage: 'fr',
  supportedLanguages: ['fr', 'en', 'de'],
  preferredLanguage: 'fr',
  firebaseConfig: {
    apiKey: 'AIzaSyAcmxvHb-t-HH5ocFcaJsusBeIKkQNeMEU',
    authDomain: 'rcsc-fb489.firebaseapp.com',
    projectId: 'rcsc-fb489',
    storageBucket: 'rcsc-fb489.appspot.com',
    messagingSenderId: '797581537348',
    appId: '1:797581537348:web:8a4f17026feb1dfa0b8c11',
  },
  chartColorCssClasses: {
    pieChartColorCssClasses: ['--primary', '--secondary', '--primary-50'],
  },
};

export const richTextConfig = {
  style: {
    fontFamily: 'TSTAR, DIN Pro',
    fontWeight: 300,
    textAlign: 'center',
    font: '16px TSTAR Light',
  },
  font: {
    list: {
      'din-2014 Light': 'sans-serif Light',
      'din-2014 Bold': 'sans-serif Bold',
      'din-2014 ExtraBold': 'sans-serif ExtraBold',
    },
  },
};

export const firebaseConfig = {
  apiKey: 'AIzaSyAcmxvHb-t-HH5ocFcaJsusBeIKkQNeMEU',
  authDomain: 'rcsc-fb489.firebaseapp.com',
  projectId: 'rcsc-fb489',
  storageBucket: 'rcsc-fb489.appspot.com',
  messagingSenderId: '797581537348',
  appId: '1:797581537348:web:8a4f17026feb1dfa0b8c11',
};
